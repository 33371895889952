<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //
            'id',
            'subject',
            helpers.createdAt
          ]"
          :config="{
            name: 'communication',
            url: 'admin/communications',
            route: '/admin/communications/sending_communications',
            duplicate: true,
            ignore_isDuplicable: true,
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Communications',
  mixins: [crudMixin],
  methods: {
    parseItems(parent, items) {
      return items.map(item => {
        item.is_principal = !!item.communication_requests.filter(cr => cr.status !== 'pending' || (cr.status === 'pending' && !cr.send_at)).length
        return item
      })
    }
  }
}
</script>
