var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //
          'id',
          'subject',
          _vm.helpers.createdAt
        ],"config":{
          name: 'communication',
          url: 'admin/communications',
          route: '/admin/communications/sending_communications',
          duplicate: true,
          ignore_isDuplicable: true,
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }